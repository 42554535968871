// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import barba from '@barba/core';
import barbaCss from '@barba/css';

// Draggable JS Plugins
import { Sortable, Plugins } from '@shopify/draggable';

// Matter JS Dependancies
var Matter = require('matter-js');
import { Engine, World, Body, Bodies, Constraint } from 'matter-js';

// IMPORT OWL CAROUSEL
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';


// On page load do
document.addEventListener("turbolinks:load", () => {

  // Check if homepage
  var isHomePage = false
  var homeLoading = false

  // MENU AND NAV
  // Track if menu is open
  var menuOpened = false
  var menuZ = 4

  // Add click listener to country
  // $('.country').click(function(){
  //   $(this).slideUp()
  //   // loadHome()
  //   // deployBlocks()
  // })

  // Open menu function
  function menuOpen() {
    // Open Menu
    $("nav").css("transform", "translateX(0%)")
    $(".menu svg").css("transform", "rotate(-45deg)")
    $('.menu h3').html("CLOSE")
    menuOpened = true
  }

  // Close menu function
  function menuClose() {
    $("nav").css("transform", "translateX(100%)")
    setTimeout(function() {
      $(".menu svg").css("transform", "")
      $('.menu h3').html("MENU")
    }, 350);
    menuOpened = false
  }

  // Open Menu on arrows click
  $(".menu").click(function() {
    if (menuOpened == false) {
      menuOpen()
    } else {
      menuClose()
    }
  })


  // header links change text
  const navLinks = document.querySelectorAll("nav h2 a")
  const href = window.location.pathname

  console.log(href)

  // read link of page and find it in the header
  navLinks.forEach(link => {
    if (link.getAttribute("href") === href) {
      link.classList.add("selected")
    } else {
      link.classList.remove("selected")
    }
  })


  // COLOUR PROFILE
  var colourProfileOn = false

  $('.tools .colour').click(function() {
    if (colourProfileOn == false) {
      $('.colourProfile').css('display', 'block')
      $('.colourProfile').css('animation', 'slideDown 0.75s forwards')
      menuClose()
      colourProfileOn = true
      console.log('changeColour')
    } else {
      $('.colourProfile').css('animation', 'slideUp 0.75s forwards')
      menuClose()
      colourProfileOn = false
      console.log('revertColour')
      setTimeout(function() {
        $('.colourProfile').css('display', 'none')
      }, 750);
    }
  })

  var gravityMessageShow = false

  $('.tools .gravity').click(function() {
    if (gravityMessageShow == false && window.innerWidth > 600) {
      $('.tools .gravity .message').css('opacity', '1')
      $('.tools .gravity .message').css('transform', 'translateY(0px)')
      gravityMessageShow = true
      setTimeout(function() {
        $('.tools .gravity .message').css('opacity', '0')
        $('.tools .gravity .message').css('transform', 'translateY(-20px)')
        gravityMessageShow = false
      }, 2000);
    }
  })

  homeLoading = true

  // Animate on page load
  $(window).on("load", function() {
    setTimeout(function() {

      // reset page positioning
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });

      // Remove loader
      console.log('Page Loaded')
      $('.loader').css('opacity', '0')

      loadHome()

    }, 750);
  })

  // Homepage load animations
  function loadHome() {
    console.log('Load Home Animation')
    var logoBlocks = document.querySelectorAll('.logo .blockWrapper')
    var counter = 0

    if (isHomePage == true) {
      dropBlocks()
      animateHomePage()
      function animateHomePage() {
        if (counter < logoBlocks.length) {
          setTimeout(function() {
            $(logoBlocks[counter]).css('animation', 'popUpOne 0.6s forwards')
            counter = counter + 1
            animateHomePage()

            // Run once animation has finished
            if (counter == logoBlocks.length) {
              setTimeout(function() {

                // $(logoBlocks).css('pointerEvents', 'all')

                // Reset animation values
                $(logoBlocks).each(function() {
                  $(this).css('opacity', '1')
                  $(this).css('animation', 'none')
                })

                // Fade in all other elements
                setTimeout(function() {
                  $('.process.dropcontainer').css('opacity', '1')
                  $('.headline').css('opacity', '1')
                  $('.homepageLinks').css('opacity', '1')
                  homeLoading = false
                }, 350);

              }, 600);
            }

          }, 250);
        }
      }
    } else {
      homeLoading = false
    }
  }

  // Load in Owl Carousel
  loadCarousel()
  function loadCarousel() {
    $(document).ready(function(){

      $('.owl-carousel').owlCarousel({
        loop: true,
        center: false,
        margin: 8,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 3000,
        autoWidth: true
      });

    })
  }

  function dropBlocks() {

    // Delay grabbing blocks on enter
    setTimeout(function() {
      console.log('After Timeout')

      // Set check for if matter deployed
      var deployedBlocks = false
      var dropBlocks = document.querySelector('#dropBlocks').offsetTop - window.innerHeight

      // document.addEventListener('scroll', checkScroll)

      // Listen to page scroll before releasing blocks
      // checkScroll()
      //
      // function checkScroll() {
      //   if (window.scrollY >= dropBlocks) {
      //     if (deployedBlocks == false) {
      //       deployBlocks()
      //       deployedBlocks = true
      //       $('.shapesFadeIn').css('animation', 'fadeOut 0.4s linear 0.4s forwards')
      //     }
      //   }
      // }

      deployBlocks()

      function deployBlocks() {
        console.log('Deploy Blocks')

        // two things that matter needs to run
        // first: an engine - computation and math
        // second: a renderer - draws out the engine

        // Create a shortcut to make our code cleaner with an alias
        const {Engine, Render, Bodies, World, MouseConstraint, Composites, Mouse} = Matter

        // where is matter being deployed
        const sectionTag = document.querySelector('section.shapes')
        console.log('Matter-Js Run')

        // what is the size of the page
        var body = document.querySelector('section.content')
        var w = window.innerWidth
        var h

        // Checks a minimum height, if not just be window height
        if (body.offsetHeight + 200 > window.innerHeight) {
          h = body.offsetHeight + 200
          if (window.innerWidth < 500) {
            h = body.offsetHeight + 300
          }
        } else {
          h = window.innerHeight - 70
        }

        const engine = Matter.Engine.create()
        const renderer = Matter.Render.create({
          element: sectionTag,
          engine: engine,
          options: {
            height: h,
            width: w,
            background: '',
            wireframes: false,
            pixelRatio: window.devicePixelRatio
          }
        })

        // ground and wall options
        const wallOptions = {
          isStatic: true,
          render: {
            visible: false
          }
        }

        // add walls
        var ground = Bodies.rectangle(w / 2, h + 8, w + 100, 100, wallOptions)
        var ceiling = Bodies.rectangle(w / 2, -20, w + 100, 100, wallOptions)
        var leftWall = Bodies.rectangle(-26, h / 2, 100, h + 100, wallOptions)
        var rightWall = Bodies.rectangle(w + 26, h / 2, 100, h + 100, wallOptions)

        if (window.innerWidth < 500) {
          ground = Bodies.rectangle(w / 2, h + 50, w + 100, 100, wallOptions)
          ceiling = Bodies.rectangle(w / 2, -40, w + 100, 100, wallOptions)
          leftWall = Bodies.rectangle(-40, h / 2, 100, h + 100, wallOptions)
          rightWall = Bodies.rectangle(w + 40, h / 2, 100, h + 100, wallOptions)
        }

        // add mouse interaction
        const mouseControl = MouseConstraint.create(engine, {
          element: sectionTag,
          constraint: {
            render: {
              visible: false
            }
          }
        })

        // allow user to scroll
        mouseControl.mouse.element.removeEventListener("mousewheel", mouseControl.mouse.mousewheel);
        mouseControl.mouse.element.removeEventListener("DOMMouseScroll", mouseControl.mouse.mousewheel);


        // Grab the widths for the inserted text blocks
        var blockWidths = new Array
        var blockHeights = new Array
        var blockSrcs = new Array
        var blocks = []
        var startingHeight = 300

        $('.hideBlocks img').each(function(index) {

          console.log($(this).width())
          blockWidths[index] = $(this).width()
          blockHeights[index] = $(this).height()
          blockSrcs[index] = $(this).attr('src')

          if (window.innerWidth > 1200) {

            // Make them into display blocks
            blocks[index] = Bodies.rectangle(w * Math.random(blockWidths[index], w - blockWidths[index]), startingHeight, blockWidths[index] / 2.6 + 2, 248 / 2.6 + 4, {
              restitution: 0.7,
              render: {
                sprite: {
                  texture: blockSrcs[index],
                  xScale: 0.4,
                  yScale: 0.4
                }
              }
            })

          } else if (window.innerWidth > 800) {

            // Make them into display blocks
            blocks[index] = Bodies.rectangle(w * Math.random(blockWidths[index], w - blockWidths[index]), startingHeight, blockWidths[index] / 2.8 - 16, 248 / 2.8, {
              restitution: 0.7,
              render: {
                sprite: {
                  texture: blockSrcs[index],
                  xScale: 0.352,
                  yScale: 0.352
                }
              }
            })

          } else {

            // Make them into display blocks
            blocks[index] = Bodies.rectangle(w * Math.random(blockWidths[index], w - blockWidths[index]), startingHeight, blockWidths[index] / 5 - 8, 248 / 5, {
              restitution: 0.7,
              render: {
                sprite: {
                  texture: blockSrcs[index],
                  xScale: 0.2,
                  yScale: 0.2
                }
              }
            })

          }

          startingHeight = startingHeight + 249 / 2.3

          // Add the block to the world
          World.add(engine.world, blocks[index]);

          // Fade in the blocks
          $('section.shapes').css('opacity', '1')

          return blocks

        })

        // add each element to the world
        World.add(engine.world, [
          ground,
          ceiling,
          leftWall,
          rightWall,
          mouseControl
        ])

        // run both the engine and the renderer
        Engine.run(engine)
        Render.run(renderer)

        // Prevent default key functions
        window.addEventListener("keydown", function(e) {
          // space and arrow keys
          if([37, 38, 39, 40].indexOf(e.keyCode) > -1) {
            e.preventDefault();
          }
        }, false);


        // Mobile Gravity Connected to Device
        $('.gravity').click(requestOrientationPermission)

        window.addEventListener('deviceorientation', function(event) {
          console.log('movement')
          engine.world.gravity.y = event.beta / 30
          engine.world.gravity.x = event.gamma / 30
        })

        function requestOrientationPermission(){
          if (window.innerWidth < 600) {
            menuClose()
            console.log('click')
            DeviceOrientationEvent.requestPermission().then(response => {
              if (response == 'granted') {
                window.addEventListener('deviceorientation', function(event) {
                  console.log('movement')
                  engine.world.gravity.y = event.beta / 30
                  engine.world.gravity.x = event.gamma / 30
                })
              }
            })
            .catch(console.error)
          }
        }

        // Change the engine gravity based on the key pressed down
        document.body.onkeyup = function(e){
          if(e.keyCode == 38){
            engine.world.gravity.y = -0.8;
            engine.world.gravity.x = 0;
          } else if(e.keyCode == 40){
            engine.world.gravity.y = 0.8;
            engine.world.gravity.x = 0;
          } else if(e.keyCode == 37){
            engine.world.gravity.x = -0.8;
            engine.world.gravity.y = 0;
          } else if(e.keyCode == 39){
            engine.world.gravity.x = 0.8;
            engine.world.gravity.y = 0;
          }
        }

        // Stop engine and render on page leave
        $('nav h2 a').click(function() {
          Engine.clear(engine)
          Render.stop(renderer)
          console.log('Stop Engine')
        })

        // document.removeEventListener('scroll', checkScroll)

      }

    }, 3000);

  }


  // Barba page transitions
  barba.use(barbaCss)

  barba.init({
    // preventRunning: true,

  	transitions: [
  		{
  			name: "fade",

        leave() {
          // create your stunning leave animation here
          console.log('out')
        },

        enter() {
          // create your amazing enter animation here
          console.log('in')
        },

  			beforeEnter() {

          console.log('out')

  				// reset page positioning
  				window.scrollTo({
  					top: 0,
  					behavior: "auto"
  				});

          // Pull main block on top of nav
          $("main").css("zIndex", menuZ)
          menuZ = menuZ + 1

          homeLoading = false

  			},

        afterEnter() {

          // Close the menu
          $(".menu svg").css("transform", "")
          $('.menu h3').html("MENU")
          setTimeout(function() {
            $("nav").css("transform", "translateX(100%)")
            $("nav").css("opacity", "0")
            menuOpened = false
            setTimeout(function() {
              $("nav").css("zIndex", menuZ)
              menuZ = menuZ + 1
              $("nav").css("opacity", "1")
            }, 400);
          }, 300);

          // Close flash message
          $('.flashWrapper').click(function() {
            $(this).css('opacity', '0')
            setTimeout(function() {
              $('.flashWrapper').css('display', 'none')
            }, 500);
          })

        },

        beforeLeave({ current, next, trigger }) {

          isHomePage = false

  				// header links change text
  				const navLinks = document.querySelectorAll("nav h2 a")
  				const href = next.url.path

  				// read link of page and find it in the header
  				navLinks.forEach(link => {
  					if (link.getAttribute("href") === href) {
  						link.classList.add("selected")
  					} else {
  						link.classList.remove("selected")
  					}
  				})

  			}

      },

      {
  			name: "none",

        leave() {
          // create your stunning leave animation here
          console.log('outBlog')
        },

        enter() {
          // create your amazing enter animation here
          console.log('inBlog')
        },

        from: {
          namespace: [
            'blogs'
          ]
        },

        to: {
          namespace: [
            'blogs'
          ]
        },
  		}

  	],

  	views: [
  		{
  			namespace: "home",

        afterEnter() {

          loadCarousel()

          // Call homepage animations
          isHomePage = true
          if (homeLoading == false) {
            console.log('Enter Home')
            loadHome()
            // dropBlocks()
          }

          // Close flash message
          $('.flashWrapper').click(function() {
            $(this).css('opacity', '0')
            setTimeout(function() {
              $('.flashWrapper').css('display', 'none')
            }, 500);
          })

          // Sortable drag and drop blocks
          const sortable = new Sortable(document.querySelectorAll('.dropcontainer'), {
            draggable: '.blockWrapper',
            sortAnimation: {
              duration: 200,
              easingFunction: 'ease-in-out',
            },
            plugins: [Plugins.SortAnimation]
          });

          // sortable.on('sortable:start', () => console.log('sortable:start'));
          sortable.on('sortable:start', function() {
            // $('.draggable-mirror .block').css('background', 'red')
            console.log('sortable:start')
          });
          sortable.on('sortable:sort', () => console.log('sortable:sort'));
          sortable.on('sortable:sorted', () => console.log('sortable:sorted'));
          sortable.on('sortable:stop', () => console.log('sortable:stop'));

          console.log('Before Timeout')

          // Add click listener to country
          // $('.country').click(function(){
          //   // $(this).slideUp()
          //   loadHome()
          //   dropBlocks()
          // })

        }
      },

      {
  			namespace: "blog",

        afterEnter() {

          // Copy to clipboard grab
          $('.grabLink').each(function(index) {
            $(this).click(function() {
              var dummy = $('<input readonly>').val(window.location.href).appendTo('body').select()
              document.execCommand('copy')
              $('input').css('display', 'none')
              $(this).html('<h4>Link Grabbed!</h4>')
            })
          })

          // INSERT IMAGES FROM DATABASE
          // Setup the bucket into an array
          var imageBucket = new Array

          // Go through each image in the bucket
          $('.imageBucket img').each(function(index) {
            // Grab the source file path and place it into an array
            imageBucket[index] = $(this).attr('src')
          })

          // Grab the image tags from the body copy
          $('.body img').each(function(index) {
            // Assign the src file path
            $(this).attr('src', imageBucket[index])
          })

          // Links go external
          $('.body a').each(function() {
             var a = new RegExp('/' + window.location.host + '/');
             if(!a.test(this.href)) {
                 $(this).click(function(event) {
                     event.preventDefault();
                     event.stopPropagation();
                     window.open(this.href, '_blank');
                 });
             }
          });

          // iFrame import
          $('.iframe').each(function() {
            var iframeContent = $(this).html()
            $(this).html("")
            $(this).replaceWith('<iframe ' + iframeContent + '></iframe>')
          })

        }

      },

      {

        namespace: "blogs",

        afterEnter() {

          $('.blog').mouseenter(function() {
            $('.imagePreviewImg').attr("src", $(this).find('.hide').html())
            $('.imagePreviewImg').css('display', 'block')
          }).mouseleave(function(){
            $('.imagePreviewImg').css('display', 'none')
          })

        }

      },

      {
  			namespace: "casestudy",

        afterEnter() {

          // Delay grabbing blocks on enter
          setTimeout(function() {

            // Set check for if matter deployed
            // var deployedBlocks = false
            var dropBlocks = document.querySelector('#dropBlocks').offsetTop - window.innerHeight

            // // Listen to page scroll before releasing blocks
            // document.addEventListener('scroll', checkScroll)
            //
            // function checkScroll() {
            //   if (window.scrollY >= dropBlocks) {
            //     if (deployedBlocks == false) {
            //       deployBlocks()
            //       deployedBlocks = true
            //       $('.shapesFadeIn').css('animation', 'fadeOut 0.4s linear 0.4s forwards')
            //     }
            //   }
            // }

            deployBlocks()
            $('.shapesFadeIn').css('animation', 'fadeOut 0.4s linear 0.4s forwards')

            function deployBlocks() {

              console.log('Deploy Blocks')

              // two things that matter needs to run
              // first: an engine - computation and math
              // second: a renderer - draws out the engine

              // Create a shortcut to make our code cleaner with an alias
              const {Engine, Render, Bodies, World, MouseConstraint, Composites, Mouse} = Matter

              // where is matter being deployed
              const sectionTag = document.querySelector('section.shapes')
              console.log('Matter-Js Run')

              // what is the size of the page
              var body = document.querySelector('section.content')
              var w = window.innerWidth
              var h = 800

              console.log(w, h)

              const engine = Matter.Engine.create()
              const renderer = Matter.Render.create({
                element: sectionTag,
                engine: engine,
                options: {
                  height: h,
                  width: w,
                  background: '',
                  wireframes: false,
                  pixelRatio: window.devicePixelRatio
                }
              })

              // ground and wall options
              const wallOptions = {
                isStatic: true,
                render: {
                  visible: false
                }
              }

              // add walls
              var ground = Bodies.rectangle(w / 2, h + 50, w + 100, 100, wallOptions)
              var ceiling = Bodies.rectangle(w / 2, -20, w + 100, 100, wallOptions)
              var leftWall = Bodies.rectangle(-26, h / 2, 100, h + 100, wallOptions)
              var rightWall = Bodies.rectangle(w + 26, h / 2, 100, h + 100, wallOptions)

              if (window.innerWidth < 500) {
                ground = Bodies.rectangle(w / 2, h + 50, w + 100, 100, wallOptions)
                ceiling = Bodies.rectangle(w / 2, -40, w + 100, 100, wallOptions)
                leftWall = Bodies.rectangle(-40, h / 2, 100, h + 100, wallOptions)
                rightWall = Bodies.rectangle(w + 40, h / 2, 100, h + 100, wallOptions)
              }

              // add mouse interaction
              const mouseControl = MouseConstraint.create(engine, {
                element: sectionTag,
                constraint: {
                  render: {
                    visible: false
                  }
                }
              })

              // allow user to scroll
              mouseControl.mouse.element.removeEventListener("mousewheel", mouseControl.mouse.mousewheel);
              mouseControl.mouse.element.removeEventListener("DOMMouseScroll", mouseControl.mouse.mousewheel);

              // Grab the widths for the inserted text blocks
              var blockWidths = new Array
              var blockHeights = new Array
              var blockSrcs = new Array
              var blocks = []
              var startingHeight = 150

              $('.hideBlocks img').each(function(index) {

                console.log($(this).width())
                blockWidths[index] = $(this).width()
                blockHeights[index] = $(this).height()
                blockSrcs[index] = $(this).attr('src')

                if (window.innerWidth > 1200) {

                  // Make them into display blocks
                  blocks[index] = Bodies.rectangle(w * Math.random(blockWidths[index], w - blockWidths[index]), startingHeight, blockWidths[index] / 2.3 - 54, 248 / 2.3, {
                    restitution: 0.7,
                    render: {
                      sprite: {
                        texture: blockSrcs[index],
                        xScale: 0.434,
                        yScale: 0.434
                      }
                    }
                  })

                } else if (window.innerWidth > 800) {

                  // Make them into display blocks
                  blocks[index] = Bodies.rectangle(w * Math.random(blockWidths[index], w - blockWidths[index]), startingHeight, blockWidths[index] / 2.8 - 16, 248 / 2.8, {
                    restitution: 0.7,
                    render: {
                      sprite: {
                        texture: blockSrcs[index],
                        xScale: 0.352,
                        yScale: 0.352
                      }
                    }
                  })

                } else {

                  // Make them into display blocks
                  blocks[index] = Bodies.rectangle(w * Math.random(blockWidths[index], w - blockWidths[index]), startingHeight, blockWidths[index] / 7 - 8, 248 / 7, {
                    restitution: 0.7,
                    render: {
                      sprite: {
                        texture: blockSrcs[index],
                        xScale: 0.142,
                        yScale: 0.142
                      }
                    }
                  })

                }

                startingHeight = startingHeight + 249 / 2

                // Add the block to the world
                World.add(engine.world, blocks[index]);

                // Fade in the blocks
                $('section.shapes').css('opacity', '1')

                return blocks

              })

              // Walls and controls
              World.add(engine.world, [
                ground,
                ceiling,
                leftWall,
                rightWall,
                mouseControl
              ])

              // engine.world.gravity.y = 0.5;

              // run both the engine and the renderer
              Engine.run(engine)
              Render.run(renderer)


              // Prevent default key functions
              window.addEventListener("keydown", function(e) {
                // space and arrow keys
                if([37, 38, 39, 40].indexOf(e.keyCode) > -1) {
                  e.preventDefault();
                }
              }, false);

              // Mobile Gravity Connected to Device
              $('.gravity').click(requestOrientationPermission)

              window.addEventListener('deviceorientation', function(event) {
                console.log('movement')
                engine.world.gravity.y = event.beta / 30
                engine.world.gravity.x = event.gamma / 30
              })

              function requestOrientationPermission(){
                if (window.innerWidth < 600) {
                  menuClose()
                  console.log('click')
                  DeviceOrientationEvent.requestPermission().then(response => {
                    if (response == 'granted') {
                      window.addEventListener('deviceorientation', function(event) {
                        console.log('movement')
                        engine.world.gravity.y = event.beta / 30
                        engine.world.gravity.x = event.gamma / 30
                      })
                    }
                  })
                  .catch(console.error)
                }
              }

              // Change the engine gravity based on the key pressed down
              document.body.onkeyup = function(e){
                if(e.keyCode == 38){
                  engine.world.gravity.y = -0.8;
                  engine.world.gravity.x = 0;
                } else if(e.keyCode == 40){
                  engine.world.gravity.y = 0.8;
                  engine.world.gravity.x = 0;
                } else if(e.keyCode == 37){
                  engine.world.gravity.x = -0.8;
                  engine.world.gravity.y = 0;
                } else if(e.keyCode == 39){
                  engine.world.gravity.x = 0.8;
                  engine.world.gravity.y = 0;
                }
              }

              // Stop engine and render on page leave
              $('nav h2 a').click(function() {
                Engine.clear(engine)
                Render.stop(renderer)
                console.log('Stop Engine')
              })

              // document.removeEventListener('scroll', checkScroll)

            }
          }, 500);

        }

      },

      {
  			namespace: "services",

        afterEnter() {

          var hiddenText = document.querySelectorAll('.hide')
          var isHidden = new Array
          var arrows = document.querySelectorAll('.arrow')

          $('.dropdown').each(function(index) {
            isHidden[index] = false
            $(this).click(function() {
              if (isHidden[index] == false) {
                $(hiddenText[index]).slideDown()
                $(arrows[index]).css('transform', 'rotate(180deg)')
                isHidden[index] = true
              } else {
                $(hiddenText[index]).slideUp()
                $(arrows[index]).css('transform', 'rotate(0deg)')
                isHidden[index] = false
              }
            })
          })

        }

      },

      {
        namespace: "clients",

        afterEnter() {

          // Preview image
          $('.blockWrapper').mouseenter(function() {
            console.log('in')
            $('.imagePreviewImg').attr("src", $(this).find('.hide').html())
            $('.imagePreviewImg').css('display', 'block')
          }).mouseleave(function(){
            console.log('out')
            $('.imagePreviewImg').attr("src", "none")
            $('.imagePreviewImg').css('display', 'none')
          })

          // Allow filters to be clicked
          $('.filter').click(function(){
            // Change appearence
            $('.filter').each(function(){
              $(this).removeClass('selected')
            })
            $(this).addClass('selected')
            // Run filter function
            filter($(this).attr('id'))
          })

          // Filter projects
          function filter(term) {
            $('.case_study').each(function(){
              $(this).css('display', 'none')
              if ($(this).hasClass(term) == true) {
                $(this).css('display', 'inline-block')
              }
            })
          }

        }
      },

      {
        namespace: "about",

        afterEnter() {

          // Rotate image randomly
          $('.portrait img').each(function(index) {
            var random = Math.random()*4 - 2;
            $(this).css('transform', 'rotate(' + random + 'deg)')
          })

        }
      },

      {
  			namespace: "approach",

        afterEnter() {

          dropBlocks()

          function dropBlocks() {

            // Delay grabbing blocks on enter
            setTimeout(function() {
              console.log('After Timeout')

              // Set check for if matter deployed
              var deployedBlocks = false
              var dropBlocks = document.querySelector('#dropBlocks').offsetTop - window.innerHeight

              deployBlocks()

              function deployBlocks() {
                console.log('Deploy Blocks')

                // two things that matter needs to run
                // first: an engine - computation and math
                // second: a renderer - draws out the engine

                // Create a shortcut to make our code cleaner with an alias
                const {Engine, Render, Bodies, World, MouseConstraint, Composites, Mouse} = Matter

                // where is matter being deployed
                const sectionTag = document.querySelector('section.shapes')
                console.log('Matter-Js Run')

                // what is the size of the page
                var body = document.querySelector('section.content')
                var w = window.innerWidth
                var h

                // Checks a minimum height, if not just be window height
                if (body.offsetHeight + 600 > window.innerHeight) {
                  h = body.offsetHeight + 0
                  if (window.innerWidth < 500) {
                    h = body.offsetHeight + 0
                  }
                } else {
                  h = window.innerHeight - 70
                }

                const engine = Matter.Engine.create()
                const renderer = Matter.Render.create({
                  element: sectionTag,
                  engine: engine,
                  options: {
                    height: h,
                    width: w,
                    background: '',
                    wireframes: false,
                    pixelRatio: window.devicePixelRatio
                  }
                })

                // ground and wall options
                const wallOptions = {
                  isStatic: true,
                  render: {
                    visible: false
                  }
                }

                // add walls
                var ground = Bodies.rectangle(w / 2, h + 40, w + 100, 100, wallOptions)
                var ceiling = Bodies.rectangle(w / 2, -20, w + 100, 100, wallOptions)
                var leftWall = Bodies.rectangle(-26, h / 2, 100, h + 100, wallOptions)
                var rightWall = Bodies.rectangle(w + 26, h / 2, 100, h + 100, wallOptions)

                if (window.innerWidth < 500) {
                  ground = Bodies.rectangle(w / 2, h + 60, w + 100, 100, wallOptions)
                  ceiling = Bodies.rectangle(w / 2, -40, w + 100, 100, wallOptions)
                  leftWall = Bodies.rectangle(-40, h / 2, 100, h + 100, wallOptions)
                  rightWall = Bodies.rectangle(w + 40, h / 2, 100, h + 100, wallOptions)
                }

                // add mouse interaction
                const mouseControl = MouseConstraint.create(engine, {
                  element: sectionTag,
                  constraint: {
                    render: {
                      visible: false
                    }
                  }
                })

                // allow user to scroll
                mouseControl.mouse.element.removeEventListener("mousewheel", mouseControl.mouse.mousewheel);
                mouseControl.mouse.element.removeEventListener("DOMMouseScroll", mouseControl.mouse.mousewheel);


                // Grab the widths for the inserted text blocks
                var blockWidths = new Array
                var blockHeights = new Array
                var blockSrcs = new Array
                var blocks = []
                var startingHeight = 300

                $('.hideBlocks img').each(function(index) {

                  console.log($(this).width())
                  blockWidths[index] = $(this).width()
                  blockHeights[index] = $(this).height()
                  blockSrcs[index] = $(this).attr('src')

                  if (window.innerWidth > 1200) {

                    // Make them into display blocks
                    blocks[index] = Bodies.rectangle(w * Math.random(blockWidths[index], w - blockWidths[index]), startingHeight, blockWidths[index] / 2.6 + 2, 248 / 2.6 + 4, {
                      restitution: 0.7,
                      render: {
                        sprite: {
                          texture: blockSrcs[index],
                          xScale: 0.4,
                          yScale: 0.4
                        }
                      }
                    })

                  } else if (window.innerWidth > 800) {

                    // Make them into display blocks
                    blocks[index] = Bodies.rectangle(w * Math.random(blockWidths[index], w - blockWidths[index]), startingHeight, blockWidths[index] / 2.8 - 16, 248 / 2.8, {
                      restitution: 0.7,
                      render: {
                        sprite: {
                          texture: blockSrcs[index],
                          xScale: 0.352,
                          yScale: 0.352
                        }
                      }
                    })

                  } else {

                    // Make them into display blocks
                    blocks[index] = Bodies.rectangle(w * Math.random(blockWidths[index], w - blockWidths[index]), startingHeight, blockWidths[index] / 10 - 8, 248 / 10, {
                      restitution: 0.7,
                      render: {
                        sprite: {
                          texture: blockSrcs[index],
                          xScale: 0.1,
                          yScale: 0.1
                        }
                      }
                    })

                  }

                  startingHeight = startingHeight + 249 / 2.3

                  // Add the block to the world
                  World.add(engine.world, blocks[index]);

                  // Fade in the blocks
                  $('section.shapes').css('opacity', '1')

                  return blocks

                })

                // add each element to the world
                World.add(engine.world, [
                  ground,
                  ceiling,
                  leftWall,
                  rightWall,
                  mouseControl
                ])

                // run both the engine and the renderer
                Engine.run(engine)
                Render.run(renderer)

                // Prevent default key functions
                window.addEventListener("keydown", function(e) {
                  // space and arrow keys
                  if([37, 38, 39, 40].indexOf(e.keyCode) > -1) {
                    e.preventDefault();
                  }
                }, false);


                // Mobile Gravity Connected to Device
                $('.gravity').click(requestOrientationPermission)

                window.addEventListener('deviceorientation', function(event) {
                  console.log('movement')
                  engine.world.gravity.y = event.beta / 30
                  engine.world.gravity.x = event.gamma / 30
                })

                function requestOrientationPermission(){
                  if (window.innerWidth < 600) {
                    menuClose()
                    console.log('click')
                    DeviceOrientationEvent.requestPermission().then(response => {
                      if (response == 'granted') {
                        window.addEventListener('deviceorientation', function(event) {
                          console.log('movement')
                          engine.world.gravity.y = event.beta / 30
                          engine.world.gravity.x = event.gamma / 30
                        })
                      }
                    })
                    .catch(console.error)
                  }
                }

                // Change the engine gravity based on the key pressed down
                document.body.onkeyup = function(e){
                  if(e.keyCode == 38){
                    engine.world.gravity.y = -0.8;
                    engine.world.gravity.x = 0;
                  } else if(e.keyCode == 40){
                    engine.world.gravity.y = 0.8;
                    engine.world.gravity.x = 0;
                  } else if(e.keyCode == 37){
                    engine.world.gravity.x = -0.8;
                    engine.world.gravity.y = 0;
                  } else if(e.keyCode == 39){
                    engine.world.gravity.x = 0.8;
                    engine.world.gravity.y = 0;
                  }
                }

                // Stop engine and render on page leave
                $('nav h2 a').click(function() {
                  Engine.clear(engine)
                  Render.stop(renderer)
                  console.log('Stop Engine')
                })

                // document.removeEventListener('scroll', checkScroll)

              }

            }, 3000);

          }

        }
      },

      {
        namespace: "mitchgoesonholiday",

        afterEnter() {

          $(document).ready(function() {
          	// Initiate gifLoop for set interval
          	var refresh;
          	// Duration count in seconds
          	const duration = 1000 * 10;
          	// Giphy API defaults
          	const giphy = {
          		baseURL: "https://api.giphy.com/v1/gifs/",
          		apiKey: "hqlUuG2wIHdt65QT7bSQSFziyF8BMuAx",
          		tag: "vacation",
          		type: "random",
          		rating: "pg-13"
          	};
          	// Target gif-wrap container
          	const $gif_wrap = $("#gif-wrap");
          	// Giphy API URL
          	let giphyURL = encodeURI(
          		giphy.baseURL +
          			giphy.type +
          			"?api_key=" +
          			giphy.apiKey +
          			"&tag=" +
          			giphy.tag +
          			"&rating=" +
          			giphy.rating
          	);

          	// Call Giphy API and render data
          	var newGif = () => $.getJSON(giphyURL, json => renderGif(json.data));

          	// Display Gif in gif wrap container
          	var renderGif = _giphy => {
          		console.log(_giphy);
          		// Set gif as bg image
          		$gif_wrap.css({
          			"background-image": 'url("' + _giphy.embed_url + '")'
          		});

          		// Start duration countdown
          		// refreshRate();
          	};

          	// Call for new gif after duration
          	var refreshRate = () => {
          		// Reset set intervals
          		clearInterval(refresh);
          		refresh = setInterval(function() {
          			// Call Giphy API for new gif
          			newGif();
          		}, 4000);
          	};

          	// Call Giphy API for new gif
          	newGif();


          	const newGifButton = $('#new-gif');

          	newGifButton.click(newGif)
          });

        }
      }

    ]
  })

})
